import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[textareaAutoresize]'
})
export class TextareaAutoresizeDirective implements OnInit {

  activeElement: any = this.elementRef.nativeElement;

  readonly minHeight: number = 70;

  constructor(private elementRef: ElementRef) { }

  @HostListener(':input')
  onInput() {
    this.resize();
  }

  ngOnInit() {
    if (this.activeElement.scrollHeight) {
      setTimeout(() => this.resize());
    }
  }

  resize() {
    this.activeElement.style.height = '0'; // Make sure that the element gets downsized when clicking backspace.

    if (this.activeElement.scrollHeight < this.minHeight) {
      this.activeElement.style.height = `${this.minHeight}px`;
    } else {
      this.activeElement.style.height = this.activeElement.scrollHeight + 'px';
    }
  }
}
