<header class="header">

</header>

<main class="content">
  <div [@routeAnimations]="o && o.activatedRouteData
      && o.activatedRouteData['animation']">
    <router-outlet #o="outlet"></router-outlet>
</div>
</main>

<footer class="footer" disableRightClick>
  <nav class="footer-nav">
    <ul class="footer-nav-list">
      <li class="footer-nav-list__item">
        <a class="footer-nav-list__item-link" routerLink="/home" routerLinkActive="footer-nav-list__item-link--active">
          <span class="icon"><mat-icon>home_round</mat-icon></span>
          <span class="text">Hem</span>
        </a>
      </li>
      <!--<li class="footer-nav-list__item">
        <a class="footer-nav-list__item-link" routerLink="/calendar" routerLinkActive="footer-nav-list__item-link--active">
          <span class="icon"><mat-icon>event_round</mat-icon></span>
          <span class="text">Kalender</span>
        </a>
      </li>-->
      <li class="footer-nav-list__item">
        <a class="footer-nav-list__item-link" routerLink="/notes" routerLinkActive="footer-nav-list__item-link--active">
          <span class="icon"><mat-icon>note_alt_round</mat-icon></span>
          <span class="text">Anteckningar</span>
        </a>
      </li>
    </ul>
  </nav>
</footer>
