<div class="wrapper">
  <div class="header-actions" disableRightClick>
    <h2 class="header-actions__title">{{ event.name }}</h2>
  </div>
  <small>{{ event.date_start }} {{ event.time_start }} - {{ event.date_end }} {{ event.time_end }}</small>
</div>

<button class="button button--round button--float u-margin-bottom">
  <mat-icon>close_round</mat-icon>
</button>
