import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { APIService } from '../api.service';

@Component({
  selector: 'app-delete-note',
  templateUrl: './delete-note.component.html',
  styleUrls: ['./delete-note.component.scss']
})
export class DeleteNoteComponent implements OnInit {

  constructor(
    private apiService: APIService
  ) { }

  credentialsForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });

  ngOnInit() {
    
  }

  onSubmit() {
    console.log('ee')
    console.log(this.credentialsForm.value.username)
    //const credentials: { username: any; password: any } = this.credentialsForm.value;
    const credentials = {"username":this.credentialsForm.value.username,"password":this.credentialsForm.value.password};
console.log(credentials)
   
    this.apiService.postCredentials(credentials).subscribe((data: any) => {
      console.log(data)
    });

    
  }

}
