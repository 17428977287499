import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = JSON.parse(localStorage.getItem('jwt'));

    if (jwt) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError(
        (error, caught) => {
          if (error.status === 401 && this.router.url !== '/login') {
            this.handleAuthError();
            return of(error);
          }

          throw error;
        }
      )
    );
  }

  private handleAuthError() {
    localStorage.removeItem('jwt');
    this.router.navigate(['/login']);
  }
}
