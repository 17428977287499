import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {
  private _defaultButtonConfig: any = {
    text: null,
    icon: null,
    isTransparent: false,
    animate: false
  };

  @Input() buttonConfig: any = {};
  @Input() isLoading: boolean;
  @Input() success: boolean;

  constructor() { }

  ngOnInit(): void {
    this.buttonConfig = {...this._defaultButtonConfig, ...this.buttonConfig};
  }

}
