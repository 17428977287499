<div class="wrapper">
  <div class="header-actions header-actions--no-space-between" disableRightClick>
    <button class="button button--transparent u-padding-left-none" (click)="goBack(id)">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h2 class="header-actions__title">Redigera anteckning</h2>
  </div>

  <form [formGroup]="credentialsForm" (ngSubmit)="onSubmit()" class="form" *ngIf="note; else skeletonLoader">
    <div class="form-group">
      <input type="text" placeholder="Titel" formControlName="username" [ngModel]="note.title" required>
    </div>
    <div class="form-group">
      <textarea formControlName="password" [ngModel]="note.body" textareaAutoresize></textarea>
    </div>
    <div class="form-group form-group--align-right">
      <app-submit-button [buttonConfig]="{
          text: 'Spara anteckning',
          icon: 'check_round'
        }" [isLoading]="isLoading">
      </app-submit-button>
    </div>
  </form>
</div>

<ng-template #skeletonLoader>
  <div class="form-group">
    <span class="skeleton-loader skeleton-loader--width-110"></span>
  </div>
  <div class="form-group">
    <span class="skeleton-loader skeleton-loader--width-80"></span>
    <span class="skeleton-loader skeleton-loader--width-110"></span>
  </div>
</ng-template>
