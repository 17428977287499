<div class="wrapper">
  <div class="header-actions fadeIn1 u-hidden" disableRightClick>
    <h2 class="header-actions__title">Skapa ny anteckning</h2>
  </div>

  <form [formGroup]="credentialsForm" (ngSubmit)="onSubmit()" class="form">
    <div class="form-group fadeIn2 u-hidden">
      <input type="text" placeholder="Titel" formControlName="username" required>
    </div>
    <div class="form-group fadeIn3 u-hidden">
      <textarea formControlName="password" textareaAutoresize></textarea>
    </div>
    <div class="form-group form-group--align-right fadeIn4 u-hidden">
      <app-submit-button [buttonConfig]="
        {
          text: 'Skapa anteckning',
          icon: 'add_round',
          isTransparent: true,
          animate: true
        }" [isLoading]="isLoading" [success]="success">
      </app-submit-button>
    </div>
  </form>
</div>

<button (click)="goBack()" class="button button--round button--float u-margin-bottom">
  <mat-icon>close_round</mat-icon>
</button>
