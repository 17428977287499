import { AuthService } from './../auth.service';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { APIService } from '../api.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { slideAnimation } from '../slideDownUpAnimation';

@Component({
  selector: 'app-note-comment',
  templateUrl: './note-comment.component.html',
  styleUrls: ['./note-comment.component.scss']
})
export class NoteCommentComponent implements AfterViewInit {

  @Input() comments: any;

  @Input() note: any;

  @ViewChild('tasknote') input: ElementRef;

  constructor(
    private _elementRef: ElementRef,
    private apiService: APIService,
    private confirmationDialogService: ConfirmationDialogService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  showDialog(noteId: number, commentId: number) {
    this.confirmationDialogService.confirmThis("Är du säker på att du vill radera denna kommentar?", () => {
      this.confirmationDialogService.closeDialog();
      slideAnimation.up(commentId);

      this.apiService.deleteComment(noteId, commentId).subscribe((data: any) => {
        if (data.status == 200) {
          this.confirmationDialogService.closeDialog();
        } else {
          // Handle error
          // use (error) instead
        }
      });
    }, () => {
      this.confirmationDialogService.closeDialog();
    });
  }

  ngAfterViewInit() {
    let element = this._elementRef.nativeElement;
    console.log(element)
  }

}
