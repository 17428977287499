import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable() export class ConfirmationDialogService {

  hasConfirmed: boolean = false;

  hasCancelled: boolean = false;

    private subject = new Subject<any>();

    confirmThis(message: string, yesFn: () => void, noFn: () => void): any {
      this.setConfirmation(message, yesFn, noFn);
    }

    setConfirmation(message: string, yesFn: () => void, noFn: () => void): any {
      let hasConfirmed = this.hasConfirmed;
      let hasCancelled = this.hasCancelled;
        this.subject.next({
            type: 'confirm',
            text: message,
            hasConfirmed: hasConfirmed,
            hasCancelled: hasCancelled,
            yesFn(): any {
              this.hasConfirmed = true;
              yesFn();
            },
            noFn(): any {
              this.hasCancelled = true;
              noFn();
            }
        });
    }

    closeDialog() {
      this.hasConfirmed = false;
      this.hasCancelled = false;
      setTimeout(() => {
        this.subject.next();
      }, 500);
    }

    getMessage(): Observable<any> {
      return this.subject.asObservable();
    }
}
