<div class="wrapper">
  <h2 disableRightClick>Logga in</h2>

  <form [formGroup]="credentialsForm" (ngSubmit)="onSubmit()" class="form">
    <div class="form-group">
      <input type="text" placeholder="Användarnamn" formControlName="username">
    </div>
    <div class="form-group">
      <input type="password" placeholder="Lösenord" formControlName="password">
    </div>
    <div class="form-group form-group--align-right">
      <button type="submit" class="button" disabled="true" [disabled]="isLoading" disableRightClick>
        <div *ngIf="isLoading">
          <mat-progress-spinner mode="indeterminate" diameter="24" class="white"></mat-progress-spinner>
        </div>
        <div *ngIf="!isLoading">
          <mat-icon>login_round</mat-icon>
        </div>
        <span>Logga in</span>
      </button>
    </div>
  </form>

  <p *ngIf="hasError">{{ errorMessage }}</p>
</div>
