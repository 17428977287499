import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  amountOfYearsNextYear: any;
  nextAnniversary: any;
  nextAnniversaryWeekday: any;
  daysUntilNextAnniversary: any;

  amountOfYearsInTwoYears: any;
  nextAnniversaryInTwoYears: any;
  nextAnniversaryInTwoYearsWeekday: any;
  daysUntilAnniversaryInTwoYears: any;

  constructor() { }

  // TODO clean up
  ngOnInit(): void {
    moment.locale('sv'); // Sets the language to Swedish

    const anniversaryDate = '2020-03-29';
    const today = moment().format("YYYY-MM-DD");
    const diffInYears = moment(today).diff(anniversaryDate, 'years'); // Number of years celebrated thus far

    const amountOfYearsNextYear = diffInYears + 1;
    const amountOfYearsInTwoYears = diffInYears + 2;

    const nextAnniversary = moment(anniversaryDate).add(amountOfYearsNextYear, 'years');
    const daysUntilNextAnniversary = moment(nextAnniversary).diff(today, 'days');

    const nextAnniversaryInTwoYears = moment(anniversaryDate).add(amountOfYearsInTwoYears, 'years');
    const daysUntilAnniversaryInTwoYears = moment(nextAnniversaryInTwoYears).diff(today, 'days');

    this.nextAnniversary = nextAnniversary.format('YYYY-MM-DD');
    this.nextAnniversaryWeekday = nextAnniversary.format('dddd');
    this.daysUntilNextAnniversary = daysUntilNextAnniversary;
    this.amountOfYearsNextYear = amountOfYearsNextYear;

    this.nextAnniversaryInTwoYears = nextAnniversaryInTwoYears.format('YYYY-MM-DD');
    this.nextAnniversaryInTwoYearsWeekday = nextAnniversaryInTwoYears.format('dddd');
    this.daysUntilAnniversaryInTwoYears = daysUntilAnniversaryInTwoYears;
    this.amountOfYearsInTwoYears = amountOfYearsInTwoYears;


    //if current date has passed anniversery...??

  }
}
