export const slideAnimation = {
  down: (elementId) => {
    setTimeout(function() {
      let target = document.getElementById(elementId);
      const duration = 1000;
      target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;
        if (display === 'none') display = 'block';
        target.style.display = display;
        let height = target.offsetHeight;
        console.log(height)
        target.style.overflow = 'hidden';
        target.style.height = "0";
        target.style.paddingTop = "0"
        target.style.paddingBottom = "0";
        target.style.marginTop = "0";
        target.style.marginBottom = "0";
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        target.style.transitionTimingFunction = "cubic-bezier(0.53, 0.05, 0.16, 0.97)";
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        window.setTimeout( () => {
          target.style.removeProperty('height');
          target.style.removeProperty('overflow');
          target.style.removeProperty('transition-duration');
          target.style.removeProperty('transition-property');
        }, duration);


    }, 0);
  },
  up: (elementId) => {
    let target = document.getElementById(elementId);
      const duration = 800;

      if (target.classList.contains("comments-list-comment--new")) {
        target.classList.remove("comments-list-comment--new");
      }

      target.classList.add("anim-fadeOut");
      target.style.transitionProperty = 'height, margin, padding';
      target.style.overflow = 'hidden';
        target.style.transitionDuration = duration + 'ms';
        target.style.transitionTimingFunction = "cubic-bezier(0.53, 0.05, 0.16, 0.97)";
        target.style.transitionDelay = duration + 35 + "ms"; // Fine tuning the delay animation with 35 ms
        target.style.boxSizing = 'border-box';
        target.style.height = target.offsetHeight + 'px';
        target.offsetHeight;
        target.style.height = "0";
        target.style.paddingTop = "0";
        target.style.paddingBottom = "0";
        target.style.marginTop = "0";
        target.style.marginBottom = "0";
  }
};
