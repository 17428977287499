import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from '../api.service';

@Component({
  selector: 'app-update-note',
  templateUrl: './update-note.component.html',
  styleUrls: ['./update-note.component.scss']
})
export class UpdateNoteComponent implements OnInit {

  isLoading: boolean = false;
  note: any;
  id: any;

  constructor(
    private apiService: APIService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.isLoading = false;
  }

  credentialsForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')

    this.apiService.getNote(this.id).subscribe((data: any) => {
      this.note = data;
      console.log(this.note)
    });
  }

  onSubmit() {
    this.isLoading = true;
    const credentials = {"title":this.credentialsForm.value.username,"body":this.credentialsForm.value.password};

    this.apiService.updateNote(this.id, credentials).subscribe((data: any) => {
      this.router.navigate([`/notes/${this.id}`]);
    });
  }

  goBack(id: any) {
    this.router.navigate([`/notes/${id}`]);
  }

}
