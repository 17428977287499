<div *ngIf="message" class="modal" tabindex="-1" role="dialog" [ngClass]="message.hasCancelled == true ? 'anim-fadeOut' : 'anim-fadeIn'" disableRightClick>
  <div class="wrapper">
    <div class="modal-content" [ngClass]="message.hasCancelled == true ? 'anim-scaleOut' : 'anim-scaleIn'">
      <div *ngIf="message?.type == 'confirm'" class="modal-body">
        <h2>Bekräfta</h2>
        <p>{{ message.text }}</p>
        <button class="button u-margin-right" (click)="message.yesFn();" [disabled]="message.hasConfirmed">
          <mat-icon>check_round</mat-icon>
          Ja
        </button>
        <button class="button button--alt" (click)="message.noFn()" [disabled]="message.hasCancelled">
          <mat-icon>close_round</mat-icon>
          Nej
        </button>
      </div>
    </div>
  </div>
</div>
