<button (click)="setMonth(-1)">&lt;</button>
{{date | date}}
<button (click)="setMonth(+1)">&gt;</button>
<table>
  <tr>
    <th class="days" *ngFor="let day of days">{{ day }}</th>
  </tr>
  <tr *ngFor="let row of dates" class="row">
  <td style="border: 1px solid #ccc; width: 100px;" *ngFor="let date of row"
    class="cell"
    [ngClass]="{grey: !isSameMonth(date)}"
    (click)="goToDay(date)">
    <!--(click)="setDate(date)">-->
    <span style="display:block;">{{date | date:'d'}}</span>
    <span *ngIf="getEvent(date) as date2" class="event" style="display:block;">
      <!--<span *ngIf="(date2.date_start) == (date|date:'yyyy-MM-dd')">-->{{ date2.name }}<!--</span>-->
      <!--<span [ngClass]="{'event--span': date2.date_start != date2.date_end}"></span>-->
    </span>
  </td>
  </tr>
</table>

<div class="modal" tabindex="-1" role="dialog" class="anim-fadeIn" disableRightClick>
  <div class="wrapper">
    <div class="modal-content" class="anim-scaleIn">
      <div class="modal-body">
        <h2>Lägg till event</h2>
        <p>{{ chosenDate }}</p>
        <label>Namn</label>
        <input type="text">
        <label>Datum från</label>
        <input type="text" value="{{ chosenDate }} {{ currentTimePlusOne }}">
        <label>Datum till</label>
        <input type="text" value="{{ chosenDate }} {{ currentTimePlusTwo }}">
        <button class="button u-margin-right">
          <mat-icon>check_round</mat-icon>
          Spara
        </button>
        <button class="button button--alt">
          <mat-icon>close_round</mat-icon>
          Stäng
        </button>
      </div>
    </div>
  </div>
</div>
