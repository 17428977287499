import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

import { APIService } from '../api.service';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss']
})
//TODO cleanup
export class CreateNoteComponent implements OnInit {

  login: Observable<{ username: any }>;

  credentialsForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });

  isLoading: boolean = false;

  success: boolean = false;

  constructor(
    private apiService: APIService,
    private router: Router
  ) {
    localStorage.setItem('hasComponentOpened', 'true');
  }

  ngOnInit() {
    document.body.classList.add('full-screen');
  }

  onSubmit() {
    this.isLoading = true;
    const credentials = {"username":this.credentialsForm.value.username,"password":this.credentialsForm.value.password};

    this.apiService.postCredentials(credentials).subscribe((data: any) => {
      this.success = true;
      localStorage.removeItem('hasComponentOpened');

      setTimeout(() => {
        this.router.navigate([`/notes/${data.id}`]);
      }, 1250);
    });
  }

  goBack() {
    this.router.navigate(['/notes']);
  }

  ngOnDestroy(): void {
    document.body.classList.remove('full-screen');
  }
}
