import { Router, RouterEvent, NavigationEnd, NavigationStart } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { APIService } from '../api.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  credentialsForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });

  previousUrl: string = null;
    currentUrl: string = null;

  scaleDown: boolean = false;
  active: boolean = false;

  notes: string[];
  isLoading: boolean;

  constructor(
    private apiService: APIService,
    private router: Router
  ) {
    if (localStorage.getItem('hasComponentOpened') !== null) {
      if (localStorage.getItem('hasComponentOpened') === 'true') {
        this.scaleDown = true;
        localStorage.removeItem('hasComponentOpened');
      }
    }

    this.isLoading = true;
  }

  ngOnInit(): void {
    this.apiService.ss().subscribe((data: any) => {
      console.log(data)
      this.notes = data;
      this.isLoading = false;
    });
  }

  addNote() {
    this.active = true;
    this.scaleDown = false;
    setTimeout(() => {
      this.router.navigate(['/notes/new']);
    }, 750);
  }

  redirectToNote(noteId) {
    this.router.navigate([`/notes/${noteId}`]);
  }

}
