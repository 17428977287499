import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogService } from './confirmation-dialog.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  hasConfirmed: boolean;

  hasCancelled: boolean;

  message: any;

  constructor(
      private confirmDialogService: ConfirmationDialogService
  ) {

  }

  ngOnInit(): any {
     /**
      *   This function waits for a message from alert service, it gets
      *   triggered when we call this from any other component
      */
      this.confirmDialogService.getMessage().subscribe(message => {
          this.message = message;
      });
  }
}
