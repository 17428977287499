import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'timeAgo' })
export class MomentPipeTimeAgo implements PipeTransform {
  transform(value: Date | moment.Moment): any {
    moment.locale('sv'); // Sets the language to Swedish
    return moment(value).fromNow();
  }
}
