<div class="wrapper" disableRightClick>
  <h2>Anteckningar</h2>
  <ul class="cards-list cards-list--grid" *ngIf="notes?.length >= 0 && !isLoading; else skeletonLoader">
    <li class="cards-list__card" (click)="redirectToNote(note.id)" *ngFor="let note of notes">
      <div class="cards-list__card-inner">
        <h3 class="cards-list__card-header">{{ note.title }}</h3>
        <p class="cards-list__card-text" style="white-space: pre-wrap">{{ note.body_short }}</p>
      </div>
    </li>
  </ul>
</div>
<button (click)="addNote()" class="button button--round button--float u-margin-bottom full-r" [ngClass]="{'full': active == true, 'full-r' : scaleDown == true }">
  <mat-icon>add_round</mat-icon>
</button>

<ng-template #skeletonLoader>
  <ul class="cards-list cards-list--grid">
    <li class="cards-list__card">
      <div class="cards-list__card-inner">
        <h3 class="cards-list__card-header">
          <span class="skeleton-loader skeleton-loader--width-80"></span>
        </h3>
        <p class="cards-list__card-text">
          <span class="skeleton-loader skeleton-loader--width-110"></span>
          <span class="skeleton-loader skeleton-loader--width-80"></span>
          <span class="skeleton-loader skeleton-loader--width-110"></span>
        </p>
      </div>
    </li>
    <li class="cards-list__card">
      <div class="cards-list__card-inner">
        <h3 class="cards-list__card-header">
          <span class="skeleton-loader skeleton-loader--width-80"></span>
        </h3>
        <p class="cards-list__card-text">
          <span class="skeleton-loader skeleton-loader--width-110"></span>
          <span class="skeleton-loader skeleton-loader--width-80"></span>
          <span class="skeleton-loader skeleton-loader--width-110"></span>
        </p>
      </div>
    </li>
    <li class="cards-list__card">
      <div class="cards-list__card-inner">
        <h3 class="cards-list__card-header">
          <span class="skeleton-loader skeleton-loader--width-80"></span>
        </h3>
        <p class="cards-list__card-text">
          <span class="skeleton-loader skeleton-loader--width-110"></span>
          <span class="skeleton-loader skeleton-loader--width-80"></span>
          <span class="skeleton-loader skeleton-loader--width-110"></span>
        </p>
      </div>
    </li>
    <li class="cards-list__card">
      <div class="cards-list__card-inner">
        <h3 class="cards-list__card-header">
          <span class="skeleton-loader skeleton-loader--width-80"></span>
        </h3>
        <p class="cards-list__card-text">
          <span class="skeleton-loader skeleton-loader--width-110"></span>
          <span class="skeleton-loader skeleton-loader--width-80"></span>
          <span class="skeleton-loader skeleton-loader--width-110"></span>
        </p>
      </div>
    </li>
  </ul>
</ng-template>
