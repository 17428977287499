import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../environments/environment';

interface CredentialsType {
  username: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class APIService {
  constructor(private httpClient: HttpClient) { }

  postCredentials(credentials: any) {
    console.log(credentials)
    return this.httpClient.post(`${environment.apiUrl}/api/notes/create`, credentials);
  }

  postComment(payload: any) {
    return this.httpClient.post(`${environment.apiUrl}/api/notes/${payload.note_id}/comments/create`, payload);
  }

  deleteComment(noteId: number, commentId: number) {
    return this.httpClient.delete(`${environment.apiUrl}/api/notes/${noteId}/comments/delete/${commentId}`);
  }

  ss() {
    return this.httpClient.get(`${environment.apiUrl}/api/s`);
  }

  getNote(id: any) {
    return this.httpClient.get(`${environment.apiUrl}/api/notes/${id}`);
  }

  updateNote(id: any, data: any) {
    return this.httpClient.put(`${environment.apiUrl}/api/notes/update/${id}`, data);
  }

  deleteNote(id: any) {
    return this.httpClient.delete(`${environment.apiUrl}/api/notes/delete/${id}`);
  }

  getHello(): Observable<{ hello?: string }> {
    return this.httpClient.get(`${environment.apiUrl}/api`);
  }

  getCurrentUser() {
    return this.httpClient.get<any>(`${environment.apiUrl}/api/user/getActiveUser`).toPromise();
  }
}
