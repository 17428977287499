<button type="submit" class="button" [ngClass]="{'button--loading': isLoading, 'button--white': buttonConfig.isTransparent}" [disabled]="isLoading && !success" disableRightClick>
  <div class="load2">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
  <div class="load">
    <mat-icon>{{ buttonConfig.icon }}</mat-icon>
    <span style="margin-left: 5px;">{{ buttonConfig.text }}</span>
  </div>
  <span class="anim-droplet" *ngIf="buttonConfig.animate" [ngClass]="success ? 'animate' : ''"></span>
</button>
