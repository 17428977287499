import { APIService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = {
    first_name: null,
    profile_pic_url: null
  };

  constructor(
    private httpClient: HttpClient,
    private apiService: APIService,
  ) {
    this.user = this.getActiveUser();
  }

  login(first_name: string, password: string) {
    const credentials = {
      username: first_name,
      password: password
    };

    // TODO cleanup!
    return this.httpClient.post(`${environment.apiUrl}/api/login_check`, { first_name, password });
      /*.pipe(
        catchError(err => {
            console.log('Handling error locally and rethrowing it...', err);
            return throwError('sfdkiod');
        })
      )
      .subscribe(
        (data: any) => {
          console.log(data)
          if (data.token) {
            localStorage.setItem('jwt', JSON.stringify(data.token));
          }
        },
        (error: any) => {
          return throwError('Användarnamn och/eller lösenord är fel.');
          //throw new Error('Användarnamn och/eller lösenord är fel.');
        }
      );
      /*.pipe(
        map(response => {
          if (response) {
            //localStorage.setItem('jwt', JSON.stringify(response));
          }
          console.log(response)
        })
      );*/
  }

  getActiveUser() {
    if (!this.isLoggedIn()) {
      return {};
    }

    return JSON.parse(localStorage.getItem('user'));
  }

  setActiveUser() {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));

      if (user !== null) {
        localStorage.removeItem('user');
      }

      this.apiService.getCurrentUser().then((response: any) => {
        user = response.data;
        localStorage.setItem('user', JSON.stringify(user));
        console.log(user)
        this.user = user;
        resolve('resolved');
      },
      (err) => {
        console.error(err);
        reject('error');
      });
    });
  }

  isLoggedIn() {
    return JSON.parse(localStorage.getItem('jwt')) !== null;
  }
}
