import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  credentialsForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });

  isLoading: boolean = false;
  hasError: boolean = false;
  errorMessage: string = null;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {

  }

  onSubmit() {
    this.isLoading = true;

    this.authService.login(this.credentialsForm.value.username, this.credentialsForm.value.password)
      .subscribe(
        (data: any) => {
          if (data.token) {
            localStorage.setItem('jwt', JSON.stringify(data.token));
            console.log('before')
            this.c().then(() => {
              console.log('after')
              this.router.navigate(['/']);
              this.isLoading = false;
            });
          }
        },
        (error: any) => {
          this.isLoading = false;
          this.hasError = true;
          this.errorMessage = 'Användarnamn och/eller lösenord är fel.'; //TODO custom success/error handler in security.yaml
        }
      );
  }

  async c() {
    await this.authService.setActiveUser();
  }

}
