import { Component } from '@angular/core';
import { slideInOutAnimation } from './slideInOutAnimation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
  ],
  animations: [ slideInOutAnimation ]
})
export class AppComponent {

}
