import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
const DAY_MS = 60 * 60 * 24 * 1000;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: [ './calendar.component.scss' ],
  providers: [DatePipe]
})
export class CalendarComponent implements OnInit {
  dates: Array<Date>;
  rows: any;
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  date = new Date();

  chosenDate: string;
  currentTimePlusOne: string;
  currentTimePlusTwo: string;


  events = [];

  //@Output() selected = new EventEmitter();

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.dates = this.getCalendarDays(this.date);
console.log(this.dates)
    this.events.push(
      {
        "name": "test event",
        "date_start": "2021-10-20",
        "time_start": "11:00",
        "date_end": "2021-10-20",
        "time_end": "12:00"
      },
      {
        "name": "test event2",
        "date_start": "2021-10-20",
        "time_start": "11:00",
        "date_end": "2021-10-20",
        "time_end": "12:00"
      },
      {
        "name": "sdfko",
        "date_start": "2021-10-25",
        "time_start": "11:00",
        "date_end": "2021-10-26",
        "time_end": "12:00"
      }
    );
    //console.log(this.getEvent('2021-10-20 11:00'))
  }

  ngOnInit() {
    var indexedArray: {[key: string]: number} = {
      foo: 2118,
      bar: 2118
    }
    //console.log(this.getEvent('2021-10-20'))
  }


  getEvent(e) {
    const f = this.datePipe.transform(e, 'yyyy-MM-dd')
    const event = this.events.find(({ date_start }) => date_start === f) || this.events.find(({ date_end }) => date_end === f);
console.log(event)
    if (typeof event === 'undefined') {
      return null;
    }

    return event;
  }

  saveEvent() {
    //this.events.push();
  }

  goToDay(date) {
    date = moment(date).format('YYYY-MM-DD');

    this.router.navigate([`/calendar/${date}`]);
  }

  setDate(date) {
    const currentHourPlusOne = moment().add(1, 'hour').format('HH');
    const currentHourPlusTwo = moment().add(2, 'hour').format('HH');
    const formatted = moment(date).format('ddd, ll');
    this.chosenDate = formatted;
    this.currentTimePlusOne = `${currentHourPlusOne}:00`;
    this.currentTimePlusTwo = `${currentHourPlusTwo}:00`;
  }

  showDialog(date) {
    this.confirmationDialogService.confirmThis(date, () => {
      this.confirmationDialogService.closeDialog();
    }, () => {
      this.confirmationDialogService.closeDialog();
    });
  }

  setMonth(inc) {
    const [year, month] = [this.date.getFullYear(), this.date.getMonth()];
    this.date = new Date(year, month + inc, 1);
    this.dates = this.getCalendarDays(this.date);
  }

  isSameMonth(date) {
    return date.getMonth() === this.date.getMonth();
  }

  private getCalendarDays(date = new Date) {
    const calendarStartTime =  this.getCalendarStartDay(date).getTime();

    let s = [];

    s.push(this.range(0, 6) // to get 6 rows 7 days
      .map(num => new Date(calendarStartTime + DAY_MS * num)));

    s.push(this.range(7, 13) // to get 6 rows 7 days
      .map(num => new Date(calendarStartTime + DAY_MS * num)));

    s.push(this.range(14, 20) // to get 6 rows 7 days
      .map(num => new Date(calendarStartTime + DAY_MS * num)));

    s.push(this.range(21, 27) // to get 6 rows 7 days
      .map(num => new Date(calendarStartTime + DAY_MS * num)));

    s.push(this.range(28, 34) // to get 6 rows 7 days
      .map(num => new Date(calendarStartTime + DAY_MS * num)));

    s.push(this.range(35, 41) // to get 6 rows 7 days
      .map(num => new Date(calendarStartTime + DAY_MS * num)));

    return s;
  }

  private getCalendarStartDay(date = new Date) {
    const [year, month] = [date.getFullYear(), date.getMonth()];
    const firstDayOfMonth = new Date(year, month, 1).getTime();

    return this.range(1,7)
      .map(num => new Date(firstDayOfMonth - DAY_MS * num))
      .find(dt => dt.getDay() === 0)
  }

  private range(start, end, length = end - start + 1) {
    return Array.from({ length }, (_, i) => start + i)
  }
}
