<div class="wrapper" disableRightClick>
  <div class="header-actions">
    <div class="header-actions__left">
      <button class="button button--transparent u-padding-left-none" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <h2 class="header-actions__title" *ngIf="note; else skeletonLoaderHeader">
        {{ note.title }}
      </h2>
    </div>
    <div class="header-actions__right">
      <button class="button button--transparent" (click)="showDialog()">
        <mat-icon>delete</mat-icon>
      </button>
      <button class="button button--transparent" (click)="handleEditAction()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <p style="white-space: pre-wrap" *ngIf="note; else skeletonLoaderBody">{{ note.body }}</p>

  <section class="comments" *ngIf="note && note.comments">
    <h3 class="u-padding-top">Kommentarer</h3>
    <ul class="comments-list">
      <app-note-comment [comments]="note.comments" [note]="note"></app-note-comment>
    </ul>
  </section>

  <form class="form u-margin-top" [formGroup]="credentialsForm" (ngSubmit)="onSubmit()" *ngIf="note">
    <div class="form-wrapper">
      <figure class="form-figure">
        <img src="{{ this.authService.user.profile_pic_url }}">
      </figure>
      <div class="form-group">
        <textarea formControlName="comment" placeholder="Skriv en kommentar..." textareaAutoresize></textarea>
      </div>
    </div>
    <div class="form-group form-group--align-right">
      <app-submit-button [buttonConfig]="
        {
          text: 'Posta kommentar',
          icon: 'add_round'
        }" [isLoading]="isLoading" [success]="success">
      </app-submit-button>
    </div>
  </form>
</div>

<ng-template #skeletonLoaderHeader>
  <span class="skeleton-loader skeleton-loader--width-110"></span>
</ng-template>

<ng-template #skeletonLoaderBody>
  <span class="skeleton-loader skeleton-loader--width-150"></span>
  <span class="skeleton-loader skeleton-loader--width-80"></span>
  <span class="skeleton-loader skeleton-loader--width-110"></span>
</ng-template>

<app-confirmation-dialog></app-confirmation-dialog>
