import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateNoteComponent } from './create-note/create-note.component';
import { DeleteNoteComponent } from './delete-note/delete-note.component';
import { HomeComponent } from './home/home.component';
import { NotesComponent } from './notes/notes.component';
import { ShowNoteComponent } from './show-note/show-note.component';
import { UpdateNoteComponent } from './update-note/update-note.component';
import { CalendarDayEventsComponent } from './calendar-day-events/calendar-day-events.component';
import { LoginComponent } from './login/login.component';
import { LoginActivateGuard } from './login-activate.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate:[LoginActivateGuard], data: {animation: 'Home'} },
  { path: 'notes', component: NotesComponent, canActivate:[LoginActivateGuard], data: {animation: 'Notes'} },
  { path: 'notes/new', component: CreateNoteComponent, canActivate:[LoginActivateGuard], data: {animation: 'CreateNote'} },
  { path: 'notes/:id', component: ShowNoteComponent, canActivate:[LoginActivateGuard], data: {animation: 'Note'} },
  { path: 'notes/:id/edit', component: UpdateNoteComponent, canActivate:[LoginActivateGuard], data: {animation: 'EditNote'} }, //TODO remove path and use note component?
  { path: 'notes/delete/:id', component: DeleteNoteComponent, canActivate:[LoginActivateGuard] },
  { path: 'calendar/:date', component: CalendarDayEventsComponent, canActivate:[LoginActivateGuard] },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
