<li #tasknote class="comments-list-comment" [ngClass]="{'comments-list-comment--new': comment.is_new}" [id]="comment.id" *ngFor="let comment of comments">
  <div style="display: flex;
  flex-direction: row;">
  <figure class="comments-list-comment__figure">
    <img src="/assets/images/{{ comment.author|lowercase }}.jpg">
  </figure>
  <div class="comments-list-comment__wrapper">
    <h4 class="comments-list-comment__name">{{ comment.author }}</h4>
    <p class="comments-list-comment__body" style="white-space: pre-wrap">{{ comment.body }}</p>
    <footer class="comments-list-comment-footer">
      <span class="comments-list-comment-footer__date">{{ comment.created_at|timeAgo }}</span>
      <span *ngIf="comment.author === this.authService.user.first_name">
        |
        <button class="button button--link" (click)="showDialog(note.id, comment.id)">
          Radera
        </button>
      </span>
    </footer>
  </div>
  </div>
</li>
