import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from '../api.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { slideAnimation } from '../slideDownUpAnimation';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-show-note',
  templateUrl: './show-note.component.html',
  styleUrls: ['./show-note.component.scss']
})
export class ShowNoteComponent implements OnInit {

  credentialsForm = new FormGroup({
    comment: new FormControl('')
  });

  id: string;
  note: any;

  isLoading: boolean = false;
  success: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private apiService: APIService,
    public authService: AuthService,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')

    this.apiService.getNote(this.id).subscribe((data: any) => {
      this.note = data;
      console.log(this.note)
    });
  }

  handleEditAction() {
    this.router.navigate([`/notes/${this.id}/edit`]);
  }

  showDialog() {
    this.confirmationDialogService.confirmThis("Är du säker på att du vill radera denna anteckning?", () => {
      this.apiService.deleteNote(this.id).subscribe((data: any) => {
        if (data.status == 200) {
          this.confirmationDialogService.closeDialog();
          this.router.navigate([`/notes`]);
        } else {
          // Handle error
        }
      });
    }, () => {
      this.confirmationDialogService.closeDialog();
    });
  }

  onSubmit() {
    this.isLoading = true;

    const payload = {
      "note_id": this.id,
      "id": null,
      "author": this.authService.user.first_name,
      "body": this.credentialsForm.value.comment,
      "is_new": true
    };

    /*const s = {
      "id": 2222,
      "isNew": true,
      "author": this.authService.user.first_name,
      "body": payload.body,
      "created_at": nowDate //add these from response
    };

    this.note.comments.push(s);

    console.log(this.note)

    slideAnimation.down(s.id);*/

    this.apiService.postComment(payload).subscribe((data: any) => {
      this.credentialsForm.reset();

      payload.id = data.comment_id;
      console.log(data)
      this.note.comments.push(payload);
      this.isLoading = false;
      this.success = true;
      slideAnimation.down(payload.id);
    }, (error) => {
      console.error(error);
    });
  }

  goBack() {
    this.router.navigate(['/notes']);
  }

}
