import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-day-events',
  templateUrl: './calendar-day-events.component.html',
  styleUrls: ['./calendar-day-events.component.scss']
})
export class CalendarDayEventsComponent implements OnInit {

  event: any = {
    "name": "test event",
    "date_start": "2021-10-20",
    "time_start": "11:00",
    "date_end": "2021-10-20",
    "time_end": "12:00"
  };

  constructor() { }

  ngOnInit(): void {
  }

}
