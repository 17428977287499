import { MomentPipeTimeAgo } from './pipes/timeAgo';
import { LoginActivateGuard } from './login-activate.guard';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './interceptor/JwtInterceptor';
import { NotesComponent } from './notes/notes.component';
import { ShowNoteComponent } from './show-note/show-note.component';
import { CreateNoteComponent } from './create-note/create-note.component';
import { HomeComponent } from './home/home.component';
import { DeleteNoteComponent } from './delete-note/delete-note.component';
import { UpdateNoteComponent } from './update-note/update-note.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from './shared/modules/material/material.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { LoginComponent } from './login/login.component';
import { TextareaAutoresizeDirective } from './textarea-autoresize.directive';
import { DisableRightClickDirective } from './disable-right-click.directive';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { NoteCommentComponent } from './note-comment/note-comment.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarEventComponent } from './calendar-event/calendar-event.component';
import { CalendarDayEventsComponent } from './calendar-day-events/calendar-day-events.component';

@NgModule({
  declarations: [
    AppComponent,
    NotesComponent,
    ShowNoteComponent,
    CreateNoteComponent,
    HomeComponent,
    DeleteNoteComponent,
    UpdateNoteComponent,
    ConfirmationDialogComponent,
    LoginComponent,
    TextareaAutoresizeDirective,
    DisableRightClickDirective,
    MomentPipeTimeAgo,
    SubmitButtonComponent,
    NoteCommentComponent,
    CalendarComponent,
    CalendarEventComponent,
    CalendarDayEventsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MaterialModule,
    MatProgressSpinnerModule
  ],
  providers: [
    ConfirmationDialogService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    LoginActivateGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
